import Alpine from 'alpinejs'
import { screenMatch } from 'helpers/screen'

export default (function () {
  let screenCallbacks = []
  window.addEventListener(
    'resize',
    () => screenCallbacks.forEach((cb) => cb()),
    true
  )

  Alpine.data('screen', () => ({
    destroy() {},
    screenMatch: screenMatch(),
    isScreen(screen) {
      return this.screenMatch[screen]
    },
    init() {
      const cb = () => {
        this.screenMatch = screenMatch()
      }
      screenCallbacks = [...screenCallbacks, cb]

      this.destroy = () => {
        screenCallbacks = screenCallbacks.filter((fn) => fn !== cb)
      }
    },
  }))
})()