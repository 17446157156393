import Alpine from 'alpinejs'

export default (function (duration = 10, max = 1) {
  Alpine.data('rotator', function () {
    const increment = max / (duration * 60) // 60 frames per second
    return {
      val: 0,
      init() {
        requestAnimationFrame(this.update.bind(this))
      },
      update() {
        this.val += increment
        if (this.val > max) this.val = 0
        requestAnimationFrame(this.update.bind(this))
      },
    }
  })
})()
