export const screens = {
  sm: '640px',
  // => @media (min-width: 640px) { ... }

  md: '768px',
  // => @media (min-width: 768px) { ... }

  lg: '1024px',
  // => @media (min-width: 1024px) { ... }

  xl: '1280px',
  // => @media (min-width: 1280px) { ... }

  '2xl': '1536px',
  // => @media (min-width: 1536px) { ... }

  '3xl': '1980px',
  // => @media (min-width: 1980px) { ... }
}

/**
 *
 * Find out if a tailwind screen value matches the current window
 *
 * @param {string} screen
 *
 * @return {Object|Boolean}
 */
export const screenMatch = (screen = '') => {
  // create a keyed object of screens that match
  const matches = Object.entries(screens).reduce((results, [name, size]) => {
    const mediaQuery =
      typeof size === 'string'
        ? `(min-width: ${size})`
        : `(max-width: ${size.max})`

    results[name] = window.matchMedia(mediaQuery).matches

    return results
  }, {})

  // show all matches when there is no screen choice
  if (screen === '') {
    return matches
  }

  // invalid screen choice
  if (!screens[screen]) {
    console.error(`No match for "${screen}"`)

    return false
  }

  return matches[screen]
}