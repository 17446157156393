let widthCache = 0
let heightCache = 0
let scrollTopCache = 0
let scrollHeightCache = 0

export const getOffset = (el) => {
  const rect = el.getBoundingClientRect()
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  }
}

export const getScrollY = () => {
  const d = document.documentElement
  return (window.pageYOffset || d.scrollTop) - (d.clientTop || 0)
}

export const isElementInView = (elm, threshold = 0) => {
  const rect = elm.getBoundingClientRect()
  const vpWidth = window.innerWidth
  const vpHeight = window.innerHeight

  const above = rect && rect.bottom - threshold <= 0
  const below = rect && rect.top - vpHeight + threshold >= 0
  const left = rect && rect.right - threshold <= 0
  const right = rect && rect.left - vpWidth + threshold >= 0
  const inside = !!rect && !above && !below && !left && !right

  return inside
}

const updateWidthCache = (width) => {
  if (widthCache === width) return

  widthCache = width
}

const updateHeightCache = (height) => {
  if (heightCache === height) return

  heightCache = height
}

const updateScrollTopCache = (scrollTop) => {
  if (scrollTopCache === scrollTop) return

  scrollTopCache = scrollTop
}

const updateScrollHeightCache = (scrollHeight) => {
  if (scrollHeightCache === scrollHeight) return

  scrollHeightCache = scrollHeight
}

const getWindowWidth = (resolve, reject) => {
  if (!document || !document.documentElement) return reject()
  return resolve(document.documentElement.clientWidth)
}

const getWindowHeight = (resolve, reject) => {
  if (!document || !document.documentElement) return reject()
  return resolve(document.documentElement.clientHeight)
}

const getScrollTop = (resolve, reject) => {
  if (!document || !document.documentElement) return reject()

  return resolve(document.documentElement.scrollTop || document.body.scrollTop)
}

const getScrollHeight = (resolve, reject) => {
  if (!document || !document.documentElement) return reject()

  return resolve(
    Math.min(
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    )
  )
}

export const scrollTop = () => {
  if (!scrollTopCache) {
    new Promise(getScrollTop).then(updateScrollTopCache)

    return getScrollTop((value) => value)
  }

  new Promise(getScrollTop).then(updateScrollTopCache)

  return scrollTopCache
}

export const scrollHeight = () => {
  if (!scrollTopCache) {
    new Promise(getScrollHeight).then(updateScrollHeightCache)

    return getScrollHeight((value) => value)
  }

  new Promise(getScrollHeight).then(updateScrollHeightCache)

  return scrollHeightCache
}

export const clientHeight = () => {
  if (!heightCache) {
    new Promise(getWindowHeight).then(updateHeightCache)

    return getWindowHeight((value) => value)
  }

  new Promise(getWindowHeight).then(updateHeightCache)

  return heightCache
}

export const clientWidth = () => {
  if (!widthCache) {
    new Promise(getWindowWidth).then(updateWidthCache)

    return getWindowWidth((value) => value)
  }

  new Promise(getWindowWidth).then(updateWidthCache)

  return widthCache
}

export const dispatch = (name, detail) => {
  const evt = new CustomEvent(name, {
    detail,
    bubbles: true,
    // Allows events to pass the shadow DOM barrier.
    composed: true,
    cancelable: true,
  })
  document.dispatchEvent(evt)
}