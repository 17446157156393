import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('masonry', function () {
    return {
      async init() {
        let Masonry

        try {
          Masonry = await import(/* webpackMode: "lazy-once" */'masonry-layout').then((m) => m.default)
        } catch (error) {
          console.log('Error loading Masonry')
        }

        if (!Masonry) return

        const instance = new Masonry(this.$el, {
          itemSelector: '[data-item]',
          columnWidth: '[data-item]',
          percentPosition: true,
          horizontalOrder: true,
        })

        try {
          let timer
          const observer = new ResizeObserver((e) => {
            if (timer) clearTimeout(timer)
            timer = setTimeout(() => {
              console.log('timed')
              instance.layout()
            }, 100)
          })
          Object.keys(this.$refs).map((key) => {
            if (key.startsWith('item')) observer.observe(this.$refs[key])
          })
        } catch (error) {
          console.log('Error observing masonry item changes')
        }
      },
    }
  })
})()
