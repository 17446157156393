import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('elemTracker', function (value) {
    return {
      ref: 0,
      init() {
        const el = this.$refs?.track || this.$root
        const measure = () => {
          this.$nextTick(() => {
            this.ref = el[value]
          })
        }

        measure()
        try {
          const obs = new ResizeObserver(measure)
          obs.observe(el)
        } catch (error) {
          // no ResizeObserver
          console.log(error)
        }
      },
    }
  })
})()