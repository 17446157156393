import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('measure', function (id) {
    return {
      init() {
        const el = this.$root
        const measure = () => {
          document.documentElement.style.setProperty(
            `--${id}`,
            el.offsetTop + el.offsetHeight + 'px'
          )
        }

        measure()
        try {
          const obs = new ResizeObserver(measure)
          ;[el, document.body].forEach(el => {
            obs.observe(el)
          })
        } catch (error) {
          // no ResizeObserver
        }
      },
    }
  })
})()