import Alpine from 'alpinejs'

export default (function () {
  let Mod = null
  Alpine.data('tilt', function() {
    return {
      async load() {
        if (!Mod) Mod = await import(/* webpackMode: "lazy-once" */'vanilla-tilt').then(mod => mod.default).catch(e => console.warn(e))
        Mod.init(this.$el, {
          reset: false,
          max: 25,
          speed: 400
        })
      }
    }
  })
})()