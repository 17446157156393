import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('sizesCollection', function () {
    return {
      sizes: [],
      track(index = 0, axis = 'y') {
        const accessor = axis === 'x' ? 'width' : 'height'
        const rect = this.$el.getBoundingClientRect()
        this.sizes[index] = rect[accessor]

        try {
          new ResizeObserver((entries) => {
            for (const entry of entries) {
              const rect = entry.contentRect
              this.sizes[index] = rect[accessor]
            }
          }).observe(this.$el)
        } catch {
        }
      },
      getSizesSum(until = 0) {
        return this.sizes.slice(0, until + 1).reduce((a, b) => a + b, 0)
      },
    }
  })
})()
