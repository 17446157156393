import Alpine from 'alpinejs'

export default (function (duration = 2) {
  /** @type {import('countup.js').CountUp} */
  let loader = null
  Alpine.data('counter', function () {
    let elem = null
    let instance = null

    return {
      async init() {
        elem = this.$refs?.['target'] || this.$el

        if (!loader) {
          loader = import(/* webpackMode: "lazy-once" */ 'countup.js')
            .then((mod) => mod.CountUp)
            .catch((e) => console.warn(e))
        }

        const Mod = await loader
        instance = new Mod(elem, parseInt(elem.innerText), {
          duration,
          separator: '.',
          decimal: ',',
        })
      },
      load() {
        loader.then(() => instance.start())
      },
    }
  })
})()
