import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('modalAjax', function (url, collection = null) {
    return {
      load() {
        if (collection && collection.length > 0) {
          this.$store.modal.items = collection
        }
        this.$store.modal.fetch(url)
      },
    }
  })

  Alpine.data('modalHtml', function () {
    return {
      load() {
        const source = this.$refs.source
        //const element = document.importNode(source.content, true);
        this.$store.modal.attach(this.$refs.source.innerHTML)
      },
    }
  })

  /** @todo maybe needs some cache cleanup */
  Alpine.store('modal', {
    cache: {},
    items: [],
    content: null,
    loading: false,
    error: null,
    current: null,
    close() {
      this.loading = false
      this.error = null
      this.content = null
    },
    getCurrentIndex() {
      return this.items.findIndex((item) => item === this.current)
    },
    hasPrevItem() {
      const index = this.getCurrentIndex(this.current)
      return !!this.items[index - 1]
    },
    hasNextItem() {
      const index = this.getCurrentIndex(this.current)
      return !!this.items[index + 1]
    },
    prevItem() {
      const index = this.getCurrentIndex(this.current)
      if (index > 0) {
        this.fetch(this.items[index - 1])
      }
    },
    nextItem() {
      const index = this.getCurrentIndex(this.current)
      if (index < this.items.length - 1) {
        this.fetch(this.items[index + 1])
      }
    },
    attach(html) {
      this.content = html
    },
    fetch(url, force = false) {
      this.current = url

      if (this.cache[url] && !force) {
        if (this.cache[url] instanceof Promise) {
          // wait for promise to resolve
          this.cache[url].then((data) => (this.content = data))
        }
        this.content = this.cache[url]
      } else {
        this.loading = true
        this.error = null

        this.cache[url] = fetch(url)
          .then((response) => response.text())
          .then((data) => {
            this.content = data
            this.loading = false

            this.cache[url] = data
          })
          .catch((error) => {
            this.error = error.message
            this.loading = false
          })
      }

      // preload -1 and +1 item from items array relative to current url
      const index = this.getCurrentIndex(url)

      if (this.items.length > 0) {
        if (this.hasPrevItem(url)) {
          const sibling = this.items[index - 1]
          if (!this.cache[sibling]) {
            this.cache[sibling] = fetch(sibling)
              .then((response) => response.text())
              .then((data) => (this.cache[sibling] = data))
          }
        }
        if (this.hasNextItem(url)) {
          const sibling = this.items[index + 1]
          if (!this.cache[sibling]) {
            this.cache[sibling] = fetch(sibling)
              .then((response) => response.text())
              .then((data) => (this.cache[sibling] = data))
          }
        }
      }
    },
  })
})()
