import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('spy', function (threshold = 0.0) {
    let body = 0
    let elem = 0
    let target = 0

    return {
      pos: 0,
      posRatio: 0,
      init() {
        if (!this.$refs['target']) return;

        window.addEventListener('resize', () => {
          body = document.documentElement.offsetWidth
        })

        new ResizeObserver((entries) => {
          elem = entries[0].contentRect.width
        }).observe(this.$root)

        new ResizeObserver((entries) => {
          target = entries[0].contentRect.width
        }).observe(this.$refs['target'])

        this.$nextTick(() => {
          body = document.documentElement.offsetWidth
          elem = this.$root.offsetWidth
          target = this.$refs['target'].offsetWidth

          // nice default
          this.pos = body / 2
          this.posRatio = elem / 2
        })
      },
      setPos(data) {
        requestAnimationFrame(() => {
          const half = target / 2
          this.pos = data
          this.posRatio = Math.max(
            Math.min(
              this.pos / body * elem,
              elem - (half + half * threshold)
            ),
            half + half * threshold
          )
        })
      },
    }
  })
})()