import Alpine from 'alpinejs'
import { screenMatch } from 'helpers/screen'

export default (function () {
  let index = 0
  const loader = () => import(/* webpackMode: "lazy-once" */'lax.js').then(mod => {
    const lax = mod.default
    lax.init()
    return lax
  }).catch(e => console.log('Error loading lax.js', e));

  Alpine.data('parallaxParent', () => ({
    index,
    parallaxInstance: null,
    init() {
      // load only on large screens
      if (!screenMatch('lg')) return
      if (!this.parallaxInstance) this.parallaxInstance = loader()

      const el = this.$el

      this.parallaxInstance.then(lax => {
        lax.addDriver(`scrollY${this.index}`, function () {
          const rect = el.getBoundingClientRect()
          return rect.top / rect.height * -100
        })
      })

      index++
    }
  }))

  Alpine.data('parallax', function (speed = 1) {
    return {
      async init() {
        // load only on large screens
        if (!screenMatch('lg')) return

        const lax = await this.parallaxInstance

        lax.addElement(this.$el, {
          // use driver relative to container
          [`scrollY${this.index}`]: {
            translateY: [
              [0, 'screenHeight'],
              [0, `-screenHeight / 2 * ${speed}`],
            ]
          }
        })
      }
    }
  })
})()
