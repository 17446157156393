import Alpine from 'alpinejs'

const BASE_SETTINGS = {
  placement: 'top',
  strategy: 'fixed',
  modifiers: [
    // {
    //   name: 'computeStyles',
    //   options: {
    //     adaptive: false, // true by default
    //   },
    // },
    {
      name: 'offset',
      options: {
        offset: [0, 40],
      },
    },
  ],
}

const toggleTooltip = (target, show, instance) => {
  if (show) {
    document.body.appendChild(target)
    instance.update()
  }
  if (!show && document.body.contains(target)) document.body.removeChild(target)

  return target
}

export default (function () {
  let createPopper = null
  let current = null

  Alpine.directive(
    'tooltip',
    (element, { expression }, { evaluate, cleanup }) => {
      let text = null
      let timer = null
      let instance = null
      let observer = null
      let tooltip = null
      const { tooltipTarget, hasTransitions = false, placement } = evaluate(expression)
      const mouseoverHandler = () => {
        if (timer) clearTimeout(timer)
        if (current) toggleTooltip(current, false, instance)
        timer = setTimeout(() => {
          current = toggleTooltip(tooltip, true, instance)
        }, 400)
      }
      const mouseoutHandler = () => {
        if (timer) clearTimeout(timer)
        toggleTooltip(tooltip, false, instance)
        current = null
      }
      const updater = () => {
        element.__popper.update()
      }

      if (tooltipTarget && tooltipTarget instanceof Element)
        text = tooltipTarget.innerHTML
      else text = tooltipTarget

      if (text && text.length > 1) {
        if (!createPopper) {
          createPopper = import(/* webpackMode: "lazy-once" */'@popperjs/core')
            .then((mod) => mod.createPopper)
            .catch((e) => console.log('Error loading Popper', e))
        }

        tooltip = document.createElement('div')
        tooltip.innerHTML = text
        tooltip.classList.add(
          'max-w-tooltip',
          'bg-black',
          'text-white',
          'leading-none',
          'text-center',
          'shadow-lg',
          'px-4',
          'py-2',
          'opacity-75',
          'rounded-full',
          'border',
          'border-white/30',
          // 'transition-transform',
          // 'duration-100',
          'z-50'
        )

        createPopper.then((createPopper) => {
          instance = createPopper(element, tooltip, placement ? {...BASE_SETTINGS, placement} : BASE_SETTINGS)
          element.__popper = instance
          element.addEventListener('mouseover', mouseoverHandler)
          element.addEventListener('mouseout', mouseoutHandler)
          if (hasTransitions) element.addEventListener('transitionend', updater)

          try {
            observer = new ResizeObserver(() => {
              element.__popper.update()
            })
            
            observer.observe(element)
          } catch (error) {
            // no resize observer possible
          }
        })

        cleanup(() => {
          if (observer) observer.disconnect()
          element.removeEventListener('mouseover', mouseoverHandler)
          element.removeEventListener('mouseout', mouseoutHandler)
          if (hasTransitions) element.removeEventListener('transitionend', updater)
        })
      }
    }
  )
})()
