import Alpine from 'alpinejs'

export default (function () {
  Alpine.directive('imagesloaded', (el, { expression }, { evaluate }) => {
    let images = [...el.querySelectorAll('img')]
    let count = images.length
    const callback = () => {
      count -= 1
      if (count < 1) evaluate(expression)
    }

    images.forEach(img => {
      const handler = () => {
        img.removeEventListener('load', handler)
        callback()
      }

      if (img.complete) callback()
      else img.addEventListener('load', handler)
    })
  })
})()
