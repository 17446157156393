import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('scrollSync', function () {
    let overflow = 0

    return {
      inrange: false,
      offset: 0,
      init() {
        window.addEventListener('scroll', this.handleScroll.bind(this))
        this.measure()
        new ResizeObserver(() => this.measure()).observe(this.$el)
      },
      measure() {
        overflow = this.$el.scrollWidth - this.$el.offsetWidth
      },
      handleScroll() {
        if (!this.inrange || overflow <= 0) return

        requestAnimationFrame(() => {
          const rect = this.$el.getBoundingClientRect()
          const viewportHeight = window.innerHeight
          const topEdgePosition = rect.top
          const thirdFourth = viewportHeight * 0.75
          const firstFourth = viewportHeight * 0.25

          if (topEdgePosition > thirdFourth) {
            // Element's top edge is below the third fourth of the viewport
            this.offset = 0
          } else if (topEdgePosition < firstFourth) {
            // Element's top edge is above the first fourth of the viewport
            this.offset = overflow
          } else {
            // Element's top edge is between the first and third fourths of the viewport
            const ratio =
              (thirdFourth - topEdgePosition) / (thirdFourth - firstFourth)
            this.offset = Math.min(
              overflow,
              Math.max(0, Math.round(overflow * ratio))
            )
          }
        })
      },
    }
  })
})()

