import Alpine from 'alpinejs'

export default (function () {
  Alpine.data('scrollRotator', function (factor = 1) {
    return {
      rotate: 0,
      init() {
        window.addEventListener('scroll', () => {
          requestAnimationFrame(() => {
            let rotation = (window.scrollY / (factor * 10)) % 720
            this.rotate = rotation > 360 ? rotation - 720 : rotation
          })
        })
      },
      targetBinding: {
        [':style']() {
          return {
            transform: `rotate(${this.rotate}deg)`,
          }
        },
      },
    }
  })
})()
